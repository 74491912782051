import Slide from "./slide";
import {CinemaInitializedEvent, PromoType, TrackingFeatureLabels} from "../types";
import {TrackingService} from "./tracking_service";
import {ActionName} from "@otto-ec/tracking-bct";

export default class ShopPromoLarge {
    private readonly trackingService: TrackingService;
    readonly slides: Array<Slide>
    private readonly promoType: PromoType;
    private readonly featureId: string;
    private readonly featureIndex: number;
    private readonly featureOrder: number;
    private readonly featureLabels: TrackingFeatureLabels;
    private readonly masFeatureTrackingLabels: TrackingFeatureLabels;
    private readonly masNextPiTrackingLabels: TrackingFeatureLabels;

    constructor(shopPromoLargeDom: Element) {
        this.promoType = this.getPromoType(shopPromoLargeDom);
        this.featureId = this.getFeatureId(shopPromoLargeDom);
        this.featureIndex = this.getFeatureIndex(shopPromoLargeDom);
        this.featureOrder = this.getFeatureOrder(shopPromoLargeDom);
        this.featureLabels = this.getFeatureLabels(shopPromoLargeDom);
        this.masFeatureTrackingLabels = this.getMasFeatureTrackingLabels(shopPromoLargeDom);
        this.masNextPiTrackingLabels = this.getMasNextPiTrackingLabels(shopPromoLargeDom);
        this.trackingService = new TrackingService()

        const slideSelector = this.isCinemaEnabled(shopPromoLargeDom) ? '.pl_carousel__slide' : '.promo_shoppromo-single-slide'
        const slideContainers = Array.from(shopPromoLargeDom.querySelectorAll(slideSelector));
        this.slides = slideContainers.map((slideDom) => new Slide(slideDom))
        this.slides.forEach((slide) => {
            slide.registerClickEventHandler(() => this.trackClick(slide, this.slides.length))
            if (slide.hasLever) {
                slide.registerLeverClickEventListener((e: Event) => this.openAndTrackLeverLargeShoppromo(e, slide, this.slides.length))
            }
        })

        if (this.isCinemaEnabled(shopPromoLargeDom)) {
            window.o_global.eventQBus.on("pattern.carousel.mounted", (e) => {
                this.trackInitialLoadedLargeShopPromo(e)
                this.registerScrollTrackingObserver(e);

            });
            window.o_global.eventQBus.on("pattern.carousel.stage.changed", ({ element, currentSlides, scrollingRight }) => {
                this.trackPreviousOrNextSlideLargeShopPromo(element, currentSlides, scrollingRight)
            });

            window.o_global.eventQBus.emit(
                "pattern.carousel.init",
                `.promo_shoppromo-large--container[data-feature-id="${this.featureId}"].js_pl_carousel`
            )
        } else {
            const fakeCinemaInitializedEvent: CinemaInitializedEvent = {
                element: shopPromoLargeDom,
                currentSlides: [0]
            }
            this.trackInitialLoadedLargeShopPromo(fakeCinemaInitializedEvent)
            this.registerScrollTrackingObserver(fakeCinemaInitializedEvent);
        }
    }

    trackedTilePositions: number[] = []

    registerScrollTrackingObserver(e: CinemaInitializedEvent): void {
        const eventFeatureIndex = this.getFeatureIndex(e.element);
        const promoType = this.getPromoType(e.element);
        if (e.element.className.includes("promo_shoppromo-large--container") && eventFeatureIndex == this.featureIndex && this.promoType == promoType) {
            const observer = new IntersectionObserver((entries) => {
                const slidesToTrack = this.getIntersectingSlides(entries);

                if (slidesToTrack.length > 0) {
                    this.trackScroll(slidesToTrack);
                    this.trackedTilePositions.push(...slidesToTrack.map(tile => tile.position));
                }

                slidesToTrack.forEach(slide => observer.unobserve(slide.slideDom));
            });

            this.slides.forEach((slide) => {
                observer.observe(slide.slideDom);
            })
        }
    }

    getIntersectingSlides(entries: IntersectionObserverEntry[]): Slide[] {
        return entries
            .filter((entry) => entry.isIntersecting)
            .map((entry) => new Slide(entry.target as HTMLElement));
    }

    trackScroll(slidesToTrack: Slide[]): void {
        if (this.trackedTilePositions.length <= 0) {
            this.trackingService.sendMiniAction(this.promoType, this.featureId, slidesToTrack, "scroll");
        }
    }

    trackInitialLoadedLargeShopPromo(e: CinemaInitializedEvent) {
        const eventFeatureIndex = this.getFeatureIndex(e.element)
        const eventPromoType = this.getPromoType(e.element);
        if (e.element.className.includes("promo_shoppromo-large--container") && eventFeatureIndex == this.featureIndex && eventPromoType == this.promoType && !e.element.getAttribute("data-tracked")) {
            e.element.setAttribute("data-tracked", String(true));
            this.trackingService.sendLargeShopPromoInitialLoadedTrackingEvent(this.promoType, this.featureOrder, this.featureId, this.slides.length, this.slides, this.featureLabels, this.masFeatureTrackingLabels);
        }
    }

    trackPreviousOrNextSlideLargeShopPromo(element: HTMLDivElement, currentSlidePositions: number[], scrollingRight: boolean): void {
        const eventFeatureIndex = this.getFeatureIndex(element);
        const eventPromoType = this.getPromoType(element);

        if (element.className.includes("promo_shoppromo-large--container") && eventFeatureIndex == this.featureIndex && eventPromoType == this.promoType) {
            let slides: Slide[] = []
            let action: ActionName;
            if (scrollingRight) {
                action = "next";
                currentSlidePositions.forEach(position => {
                    if (this.slides[position]) {
                        slides.push(this.slides[position]);
                    }
                });
            } else {
                action = "previous";
                slides = []
            }
            this.trackingService.sendLargeShoppromoSlideTrackingEvent(this.promoType, this.featureOrder, this.featureId, this.slides.length, slides, this.featureLabels, this.masFeatureTrackingLabels, action);
        }
    }

    trackClick(slide: Slide, filledSlots: number) {
        this.trackingService.sendLargeShopPromoClickTrackingEvent(this.promoType, this.featureOrder, this.featureId, filledSlots, slide, this.featureLabels, this.masFeatureTrackingLabels, this.masNextPiTrackingLabels);
    }

    openAndTrackLeverLargeShoppromo(event: Event, slide: Slide, filledSlots: number) {
        /*                                                                                                                                                 */
        /*                                  */
        event.preventDefault();
        event.stopPropagation();
        const leverSheet = new window.o_global.pali.sheetBuilder({
            url: (event.target as HTMLElement).getAttribute("href"),
            lockMobileHeight: false
        });
        leverSheet.open();
        this.trackingService.sendLargeShopPromoLeverTrackingEvent(this.promoType, this.featureOrder, this.featureId, filledSlots, slide, this.featureLabels, this.masFeatureTrackingLabels);
    }

    private getPromoType(container: Element): PromoType {
        return container.getAttribute('data-promo-type')! as PromoType;
    }

    private getFeatureOrder(container: Element): number {
        const dataFeatureOrder = container.closest('[data-feature-order]')?.getAttribute('data-feature-order');
        return dataFeatureOrder ? parseInt(dataFeatureOrder, 0) : -1;
    }

    private getMasFeatureTrackingLabels(container: Element): TrackingFeatureLabels {
        const dataFeatureTrackingLabels = container.closest('[data-feature-tracking-labels]')?.getAttribute("data-feature-tracking-labels");
        return dataFeatureTrackingLabels ? JSON.parse(dataFeatureTrackingLabels) : {};
    }

    private getMasNextPiTrackingLabels(container: Element): TrackingFeatureLabels {
        const dataMasNextPiTrackingLabels = container.closest('[data-next-pi-tracking-labels]')?.getAttribute("data-next-pi-tracking-labels");
        return dataMasNextPiTrackingLabels ? JSON.parse(dataMasNextPiTrackingLabels) : {};
    }

    private getFeatureId(container: Element): string {
        return container.getAttribute('data-feature-id')!;
    }

    private getFeatureIndex(container: Element): number {
        return parseInt(container.getAttribute('data-feature-index')!, 0);
    }

    private getFeatureLabels(container: Element): TrackingFeatureLabels {
        return JSON.parse(container.getAttribute('data-feature-labels')!);
    }

    private isCinemaEnabled(container: Element): boolean {
        return container.querySelector(".pl_carousel__stage") != null
    }
}