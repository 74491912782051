import Tile from "./tile";
import {getPagecluster} from "@otto-ec/global-resources/misc";
import {Action, ActionName, DataContainer, Feature, FeatureStatus, MiniAction, submitEvent, submitMerge, submitMiniAction, submitMove, trackOnNextPageImpression} from "@otto-ec/tracking-bct";

export class TrackingService {

    public sendSmallShoppromoInitialLoadedTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, tilesCount: number, tilesToTrack: Array<Tile>, masFeatureTrackingLabels: DataContainer): void {
        const trackingFeatures: Feature[] = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, tilesCount, "loaded", masFeatureTrackingLabels),
            ...tilesToTrack.map((tile) => {
                return this.createSmallShoppromoTileFeature(tile, featureId, "loaded")
            })
        ];
        submitMerge({}, trackingFeatures)
    }

    public sendSmallShoppromoSlideTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, tilesCount: number, tilesToTrack: Array<Tile>, movementType: ActionName, masFeatureTrackingLabels: DataContainer) {
        const trackingFeatures: Feature[] = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, tilesCount, "visible", masFeatureTrackingLabels),
            ...tilesToTrack
                .filter((tile) => !tile.isTrackedAsVisible())
                .map((tile) => {
                    tile.setIsTrackedAsVisible()
                    return this.createSmallShoppromoTileFeature(tile, featureId, "visible")
                })
        ];
        const trackingAction: Action = {name: movementType, features: trackingFeatures}
        submitEvent({}, trackingAction)
    }

    public sendSmallShoppromoClickTrackingEvent(featureOrder: number, featureIndex: number, featureId: string, filledSlots: number, tile: Tile, masFeatureTrackingLabels: DataContainer, masNextPiTrackingLabels: DataContainer) {
        const trackingFeatures = [
            this.createSmallShoppromoFeature(featureOrder, featureIndex, featureId, filledSlots, "clicked", masFeatureTrackingLabels),
            this.createSmallShoppromoTileFeature(tile, featureId, "clicked")
        ];
        const trackingAction: Action = {
            name: "click",
            features: trackingFeatures
        }
        const wkTrackingData = {
            "wk.promo_AttributionFeature": [`ShopPromotionSmall_${getPagecluster()}`],
            "wk.promo_AttributionSource": ["manual"]
        }

        submitMove(wkTrackingData, trackingAction)
        const existingNextPiTrackingLabels = {'promo_Click': ["ShopPromotionSmall"]}
        const nextPiTrackingLabels = {
            ...existingNextPiTrackingLabels,
            ...masNextPiTrackingLabels
        }
        trackOnNextPageImpression(nextPiTrackingLabels)
    }

    private createSmallShoppromoFeature(featureOrder: number, featureIndex: number, featureId: string, filledSlots: number, status: FeatureStatus, masFeatureTrackingLabels: DataContainer): Feature {
        const trackingLabels: DataContainer = {
            promo_FeatureNumber: [(featureIndex + 1).toString()],
            promo_FilledSlots: [(filledSlots).toString()],
            promo_Feature: ["true"],
            ...masFeatureTrackingLabels
        };

        return {
            id: `ft3_spsnfma_${featureId}`,
            name: "ShopPromotionSmall",
            position: featureOrder,
            status: status,
            labels: trackingLabels
        }
    }

    private createSmallShoppromoTileFeature(tile: Tile, featureId: string, status: FeatureStatus): Feature {
        const parentId = `ft3_spsnfma_${featureId}`
        return {
            id: parentId + "_tile" + tile.position,
            parentId: parentId,
            name: "ShopPromotionSmall-Tile",
            position: tile.position,
            status: status,
            labels: tile.featureTrackingLabels,
            variationId: "none"
        }
    }

    sendMiniAction(featureId: string, tilesToTrack: Tile[], action: ActionName) {
        const parentId = `ft3_spsnfma_${featureId}`
        const miniActions: MiniAction[] = tilesToTrack
            .filter((tile) => !tile.isTrackedAsVisible())
            .map((tile) => {
                tile.setIsTrackedAsVisible()
                return {featureId: parentId + "_tile" + tile.position, action: action}
            })
        if (miniActions.length > 0) {
            miniActions.push({featureId: parentId, action: action})
            submitMiniAction(miniActions)
        }
    }
}