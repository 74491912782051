import Slide from "./slide"
import {Action, ActionName, DataContainer, Feature, FeatureStatus, MiniAction, submitEvent, submitMiniAction, trackOnNextPageImpression, submitMove, submitMerge} from "@otto-ec/tracking-bct";
import {PromoType} from "../types";

/*                                                                                           */
/*                                                                                                       */
export class TrackingService {
    sendLargeShopPromoInitialLoadedTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slides: Array<Slide>,
                                                 featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer): void {
        if (slides.length === 0) {
            return
        }
        const trackingFeatures = this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, slides, "loaded", "loaded");
        submitMerge({}, trackingFeatures)
    }

    sendLargeShoppromoSlideTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slides: Array<Slide>,
                                         featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer, movementType: ActionName) {
        const slidesForTracking = slides.filter(slide => !slide.isTrackedAsVisible());
        slidesForTracking.forEach(slide => slide.setIsTrackedAsVisible());
        const trackingAction: Action = {
            name: movementType,
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, slidesForTracking, "visible", "visible")
        }
        const allowToTrackEmptySlides = movementType === "previous"
        if (slidesForTracking.length > 0 || allowToTrackEmptySlides) {
            submitEvent({}, trackingAction)
        }
    }

    sendLargeShopPromoClickTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slide: Slide,
                                         featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer, masNextPiTrackingLabels: DataContainer) {
        const trackingAction: Action = {
            name: "click",
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, [slide], "clicked", "unused")
        }
        const pageCluster = window.o_util.misc.getPagecluster();
        const wkTrackingData: DataContainer = this.createWkTrackingData(promoType, pageCluster, slide);
        submitMove(wkTrackingData, trackingAction)
        const existingNextPiTrackingLabels = {'promo_Click': [promoType]}
        const nextPiTrackingLabels =  {
            ...existingNextPiTrackingLabels,
            ...masNextPiTrackingLabels
        }
        trackOnNextPageImpression(nextPiTrackingLabels)
    }

    sendLargeShopPromoLeverTrackingEvent(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, slide: Slide,
                                         featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer) {
        const trackingAction: Action = {
            name: "open",
            features: this.createTrackingFeatures(promoType, featureOrder, featureId, filledSlots, featureLabels, masFeatureTrackingLabels, [slide], "clicked", "clicked")
        }
        submitEvent({}, trackingAction)
    }

    sendMiniAction(promoType: PromoType, featureId: string, slidesToTrack: Slide[], action: ActionName) {
        const miniActions: MiniAction[] = slidesToTrack
            .filter((slide) => !slide.isTrackedAsVisible())
            .map((slide) => {
                slide.setIsTrackedAsVisible()
                return {featureId: this.getIdPrefixForPromoType(promoType) + "_" + featureId + "_tile" + slide.position, action: action}
            })
        if (miniActions.length > 0) {
            miniActions.push({featureId: this.getIdPrefixForPromoType(promoType) + "_" + featureId, action: action})
            submitMiniAction(miniActions)
        }
    }

    private createTrackingFeatures(promoType: PromoType, featureOrder: number, featureId: string, filledSlots: number, featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer, slides: Array<Slide>, status: FeatureStatus, leverStatus: FeatureStatus): Feature[] {
        const trackingFeatures = [this.createLargeShopPromoTrackingFeature(promoType, featureOrder, featureId, filledSlots, status, featureLabels, masFeatureTrackingLabels)];

        slides.forEach((slide) => {
            const slideTrackingPayload = this.createLargeShoppromoTileTrackingFeature(promoType, slide.position, featureId, status, slide.featureLabels);
            trackingFeatures.push(slideTrackingPayload);
            const leverLabel = this.createLeverTagLabel(slideTrackingPayload["id"], leverStatus, slide);
            if (leverLabel) {
                trackingFeatures.push(leverLabel);
            }
        });
        return trackingFeatures;
    }

    private createWkTrackingData(promoType: "ShopPromotionLarge" | "ShopPromotionLargeDynamo", pageCluster: string | null, slide: Slide): DataContainer {
        if (promoType === 'ShopPromotionLarge') {
            return {
                "wk.promo_AttributionFeature": [promoType + "_" + pageCluster],
                "wk.promo_AttributionSource": ["manual"],
                "wk.promo_AttributionScore": ["ShopPromotionLarge"]
            }
        } else {
            /*                         */
            return {
                "wk.promo_AttributionFeature": [promoType + "_" + pageCluster],
                "wk.promo_AttributionSource": [slide.featureLabels["dynamo_Affinity"][0]],
                "wk.promo_AttributionScore": [slide.featureLabels["dynamo_Score"][0]]
            }
        }
    }


    private createLargeShopPromoTrackingFeature(promoType: string, featureOrder: number, featureId: string, filledSlots: number, status: FeatureStatus,
                                                featureLabels: DataContainer, masFeatureTrackingLabels: DataContainer): Feature {
        const mergedTrackingLabels = {
            ...featureLabels,
            ...masFeatureTrackingLabels
        }
        return {
            id: this.getIdPrefixForPromoType(promoType) + "_" + featureId,
            name: promoType,
            position: featureOrder,
            status: status,
            labels: mergedTrackingLabels
        }
    }

    private getIdPrefixForPromoType(promoType: string): string {
        if (promoType === "ShopPromotionLarge") {
            /*                     */
            return "ft3_splm";
        } else {
            /*                         */
            return "ft3_spla";
        }
    }

    private createLargeShoppromoTileTrackingFeature(promoType: string, positionInSlider: number, featureId: string, status: FeatureStatus, labels: DataContainer): Feature {
        const idPrefix = this.getIdPrefixForPromoType(promoType);
        return {
            id: idPrefix + "_" + featureId + "_tile" + positionInSlider,
            parentId: idPrefix + "_" + featureId,
            name: promoType + "-Tile",
            position: positionInSlider,
            status: status,
            labels: labels
        }
    }

    private createLeverTagLabel(parentId: string, status: FeatureStatus, slide: Slide): Feature | null {
        if (!slide.hasLever || !slide.leverTrackingPayload) {
            return null;
        }
        return {
            ...slide.leverTrackingPayload,
            id: parentId + "_b",
            parentId: parentId,
            status: status,
            position: slide.position
        };
    }
}